import React from 'react';
import styled from 'styled-components';

import { ContentWrapper } from 'components/core';

import { OrderTypeToggle } from '../../order-type-toggle';
import DispensaryHeaderDetails from '../../dispensary-header-details';
import DispensaryHeaderMenuTypeSelect from '../../dispensary-header-menu-type-select';

export default function DispensaryHeaderDesktop({
  dispensary,
  showOrderTypeToggle,
  menuType,
  handleMenuTypeChange,
  disableMenuTypeSelect,
}) {
  return (
    <StyledContentWrapper data-cy='dispensary-header'>
      <Container>
        <DispensaryHeaderDetails dispensary={dispensary} />

        <OtherDetails>
          {showOrderTypeToggle && <OrderTypeToggle checkDeliveryBoundary />}

          <DispensaryHeaderMenuTypeSelect
            menuType={menuType}
            handleMenuTypeChange={handleMenuTypeChange}
            isDisabled={disableMenuTypeSelect}
          />
        </OtherDetails>
      </Container>
    </StyledContentWrapper>
  );
}

export const StyledContentWrapper = styled(ContentWrapper)`
  flex-grow: 0;
  background: linear-gradient(
    125deg,
    ${({ theme }) => theme.colors.primaryGrey},
    ${({ theme }) => theme.colors.primaryGrey} 63.5%,
    ${({ theme }) => theme.colors.blueGrey[95]} 36.5%
  );
  .content-wrapper {
    max-width: 1536px;
    padding-left: 75px;
    padding-right: 75px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 195px;
  padding: 61px 0 51px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const OtherDetails = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 36.5%;
  place-content: flex-end;

  div[class*='select__StyledSelect'] {
    margin-left: 22px;
  }
`;
