import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useObserver } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import { DesktopOnly, MobileOnly } from 'shared/components';
import useCart from 'hooks/use-cart';
import useSearchPreferences from 'hooks/use-search-preferences';
import useColors from 'hooks/use-colors';
import usePaths from 'hooks/use-paths';
import useTranslation from 'hooks/use-translation';
import useOrderType from 'hooks/use-order-type';

import { durationEstimatesForOrder } from 'shared/helpers/order-estimates';
import metaInfoBuilder from './dispensary-header-meta-info-builder';

// TODO: Refactor to gain clarity between this file and dipsensary-header-meta-info-builder
//  https://dutchie.atlassian.net/browse/ENG-5132

const infoSeparator = (color, idx) => (
  <InfoSeparator key={`${color}+${idx}`} color={color}>
    •
  </InfoSeparator>
);

const MetaInfo = ({ dispensary }) => {
  const [durationEstimates, setDurationEstimates] = useState({ pickup: {}, delivery: {} });
  const [metaData, setMetaData] = useState(null);
  const { t } = useTranslation();
  const colors = useColors();
  const pathToInfoPage = usePaths({ info: true });
  const { pathname: currentRoute } = useRouter();
  const Cart = useCart();
  const address = useObserver(() => Cart.address);
  const hasAddress = useObserver(() => Cart.hasAddress);
  const hasDeliveryAddress = useObserver(() => Cart.hasDeliveryAddress);
  const simplifiedOrderType = useObserver(() => Cart.simplifiedOrderType);
  const isCartEmpty = useObserver(() => Cart.isEmpty);
  const SearchPreferences = useSearchPreferences();
  const orderTypePreference = useObserver(() => SearchPreferences.orderType);
  const { getOrderType } = useOrderType();

  useEffect(() => {
    let isCancelled = false;

    async function getEstimates() {
      if (dispensary && address) {
        const estimates = await durationEstimatesForOrder(dispensary, address, { skipMatrixCheck: true });

        if (!isCancelled) {
          setDurationEstimates(estimates);
        }
      }
    }
    getEstimates();

    return () => {
      isCancelled = true;
    };
  }, [address, dispensary]);

  useEffect(() => {
    const orderType = getOrderType();
    setMetaData(
      metaInfoBuilder({
        dispensary,
        pathToInfoPage,
        orderType,
        durationEstimates,
        hasDeliveryAddress,
        t,
        currentRoute,
        hasAddress,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispensary,
    simplifiedOrderType,
    orderTypePreference,
    durationEstimates,
    hasDeliveryAddress,
    t,
    currentRoute,
    hasAddress,
    isCartEmpty,
  ]);

  if (!metaData) {
    return null;
  }

  function metaInfoLine(metaInfo, color) {
    return _.reduce(metaInfo, (prev, curr, idx) => [prev, curr && infoSeparator(color, idx), curr]);
  }

  const metaInfoLineOne = metaInfoLine(metaData.top, colors.grey[55]);
  const metaInfoLineTwo = metaInfoLine(metaData.bottom, colors.grey[30]);

  return (
    <>
      <DesktopOnly>
        <DetailRow>
          {metaInfoLineOne}
          {!!metaInfoLineTwo && metaInfoLineOne && infoSeparator(colors.grey[55])}
          {metaInfoLineTwo}
        </DetailRow>
      </DesktopOnly>

      <MobileOnly>
        <DetailRow>{metaInfoLineOne}</DetailRow>
        <DetailRow>{metaInfoLineTwo}</DetailRow>
      </MobileOnly>
    </>
  );
};

export default MetaInfo;

const DetailRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InfoSeparator = styled.span`
  margin: 0 6px;
  color: ${({ color }) => color};
`;
