import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import useTranslation from 'hooks/use-translation';

import { MENU_TYPE_MED, MENU_TYPE_REC } from 'shared/constants';
import { DesktopOnly, MobileOnly } from 'shared/components';

import { Select, Option } from 'components/core';

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: 2,
  },
}));

export function MenuTypeSelect({ menuType, handleMenuTypeChange, isDisabled }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const truncatedMenu = {
    rec: t('common.recreational', 'Rec'),
    med: t('common.medical', 'Med'),
  };

  const unabridgedMenu = {
    rec: t('common.recreational', 'Recreational'),
    med: t('common.medical', 'Medical'),
  };

  return (
    <StyledSelect
      data-testid='select'
      value={menuType}
      disabled={isDisabled}
      MenuProps={{ classes: { paper: classes.paper } }}
      renderValue={(value) => (
        <OptionWrapper>
          {t('common.menu', 'Menu:')}
          <BoldWrapper>
            <DesktopOnly>{unabridgedMenu[value]}</DesktopOnly>
            <MobileOnly>{truncatedMenu[value]}</MobileOnly>
          </BoldWrapper>
        </OptionWrapper>
      )}
      aria-label={t('dispensary-header.select-menu-type', 'Select menu type')}
      onChange={handleMenuTypeChange}
    >
      <Option value={MENU_TYPE_REC}>{unabridgedMenu.rec}</Option>
      <Option value={MENU_TYPE_MED}>{unabridgedMenu.med}</Option>
    </StyledSelect>
  );
}

const OptionWrapper = styled.div`
  &&& {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      color: ${({ theme }) => theme.colors.grey[45]};
      font-size: 12px;
    }
    margin-left: 0;
  }
`;

const BoldWrapper = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0 3px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    color: ${({ theme }) => theme.colors.grey[45]};
  }
  &&& > div {
    margin-left: 0;
  }
`;

const StyledSelect = styled(Select)`
  height: 33px;
  &&& .MuiSelect-root {
    height: 100%;
  }
  &.MuiInputBase-root {
    .MuiSelect-outlined {
      padding-left: 19px;
      padding-right: ${({ disabled }) => (disabled ? `19px` : `32px`)};
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      .MuiSelect-outlined {
        padding-left: 15px;
      }
    }

    .Mui-disabled {
      background: ${({ theme }) => theme.colors.primaryGrey};
    }

    .MuiSelect-icon {
      ${({ disabled }) => disabled && `display: none`};
    }
  }
`;
