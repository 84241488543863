import React, { useEffect, useRef } from 'react';
import { useObserver } from 'mobx-react-lite';

import useUI from 'hooks/use-ui';
import useCart from 'hooks/use-cart';

import DispensarySubheaderDesktop from './dispensary-subheader-desktop';

export default function DispensarySubheaderWrapper({ dispensary }) {
  const UI = useUI();
  const cart = useCart();
  const itemCount = useObserver(() => cart.itemCount);
  const isGlobalHeaderInView = useObserver(() => UI.isGlobalHeaderInView);
  const isDispensaryHeaderInView = useObserver(() => UI.isDispensaryHeaderInView);
  const showCartDrawer = useObserver(() => UI.showCartDrawer);

  const deliveryMinimum = dispensary?.deliveryInfo?.minimum;

  const handleToggleCart = () => (showCartDrawer ? UI.closeCartDrawer() : UI.openCartDrawer());

  const recDispensary = useObserver(() => dispensary.recDispensary);
  const medicalDispensary = useObserver(() => dispensary.medicalDispensary);
  const showMenuTypeDropdown = recDispensary && medicalDispensary;

  const cartButtonRef = useRef();
  useEffect(() => {
    UI.setCartButtonRef(cartButtonRef);
    return () => UI.clearCartButtonRef();
  }, [UI, cartButtonRef]);

  return (
    <DispensarySubheaderDesktop
      dispensary={dispensary}
      itemCount={itemCount}
      cartButtonRef={cartButtonRef}
      isGlobalHeaderInView={isGlobalHeaderInView}
      isDispensaryHeaderInView={isDispensaryHeaderInView}
      deliveryMinimum={deliveryMinimum}
      recDispensary={recDispensary}
      showMenuTypeDropdown={showMenuTypeDropdown}
      showCart={showCartDrawer}
      onToggleCart={handleToggleCart}
    />
  );
}
