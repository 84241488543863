import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useObserver } from 'mobx-react-lite';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import useUI from 'hooks/use-ui';
import usePaths from 'hooks/use-paths';
import useMenuFilterOptions from 'hooks/use-menu-filter-options';

import ROUTES from 'src/routes';
import useProductQueryParams from 'src/hooks/use-product-query-params';
import DispensarySubheaderMobile, { CATEGORIES, SPECIALS, INFO, BRANDS, SEARCH } from './dispensary-subheader-mobile';

const matchRouteWithTab = (route) => {
  if (_.includes(route, SPECIALS)) {
    return SPECIALS;
  }
  if (_.includes(route, INFO)) {
    return INFO;
  }
  if (_.includes(route, BRANDS)) {
    return BRANDS;
  }
  return '';
};

export default function DispensarySubheaderWrapper() {
  const UI = useUI();
  const isDispensaryHeaderInView = useObserver(() => UI.isDispensaryHeaderInView);
  const { brands, hasProductsOnSpecial } = useMenuFilterOptions({
    skipCurrentFilters: true,
  });
  const hasBrands = !_.isEmpty(brands);

  const { route, asPath } = useRouter();
  const matchedRouteTab = matchRouteWithTab(asPath);
  const [selectedTab, setSelectedTab] = useState(matchedRouteTab);
  useEffect(() => {
    setSelectedTab(matchedRouteTab);
  }, [matchedRouteTab]);

  const subheaderRef = useRef();

  const { href: infoHref } = usePaths({ info: true });
  const { href: specialsHref } = usePaths({ specials: true });
  const { href: brandsHref } = usePaths({ brands: true });
  const { href: categoriesHref } = usePaths({ categories: true });

  const showSearchInputInitialValue = useObserver(() => UI.showSearchInput) || _.includes(asPath, SEARCH);
  const [showSearchInput, setShowSearchInput] = useState(showSearchInputInitialValue);

  const { queryParams } = useProductQueryParams();
  const viewingSearchResults = !_.isEmpty(queryParams?.search);

  const showEmptySearchWhiteSpace = useObserver(() => UI.showEmptySearchWhiteSpace);

  useEffect(() => {
    UI.showEmptySearchWhiteSpace = showSearchInput && !viewingSearchResults;
    return () => (UI.showEmptySearchWhiteSpace = false);
  }, [UI, showSearchInput, viewingSearchResults]);

  useEffect(() => {
    UI.showEmptySearchWhiteSpace = false;
    if (!_.includes(asPath, SEARCH)) {
      setShowSearchInput(false);
    }
  }, [UI, asPath]);

  useEffect(() => {
    if (showEmptySearchWhiteSpace) {
      disableBodyScroll();
    } else {
      enableBodyScroll(subheaderRef.current);
    }

    return () => {
      enableBodyScroll(subheaderRef.current);
    };
  }, [showEmptySearchWhiteSpace]);

  const shouldStick = useMemo(() => {
    const onDispensaryHomePage = route === ROUTES.DISPENSARY_CNAME;
    return showSearchInput || (onDispensaryHomePage && !isDispensaryHeaderInView) || route !== ROUTES.DISPENSARY_CNAME;
  }, [isDispensaryHeaderInView, route, showSearchInput]);

  const defaultTab = route !== ROUTES.DISPENSARY_CNAME ? CATEGORIES : false;

  return (
    <DispensarySubheaderMobile
      subheaderRef={subheaderRef}
      selectedTab={selectedTab || defaultTab}
      onSelectTab={(_e, tab) => setSelectedTab(tab)}
      shouldStick={shouldStick}
      infoHref={infoHref}
      specialsHref={specialsHref}
      brandsHref={brandsHref}
      categoriesHref={categoriesHref}
      onToggleSearchInput={() => setShowSearchInput(!showSearchInput)}
      showSearch={showSearchInput}
      hasSpecials={hasProductsOnSpecial}
      showEmptySearchWhiteSpace={showEmptySearchWhiteSpace}
      hasBrands={hasBrands}
    />
  );
}
