import React, { forwardRef, useLayoutEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { width } from 'styled-system';

import useColors from 'hooks/use-colors';
import useTranslation from 'hooks/use-translation';
import useFeatureFlags from 'hooks/use-feature-flags';

import SearchIcon from 'assets/search-icon';
import { Tab, Tabs } from 'components/core';
import { ARIA_ROLES } from 'shared/constants';
import { TabLink } from 'src/components/core/tabs/tab-link';
import Search from 'src/dispensary/components/dispensary-header/search';
import useDispensary from 'dispensary/hooks/use-dispensary';
import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';

export const PRODUCTS = 'products';
export const CATEGORIES = 'categories';
export const BRANDS = 'brands';
export const SPECIALS = 'specials';
export const INFO = 'info';
export const SEARCH = 'search';

// MUI wants to auto apply a bunch of aria settings that don't quite make sense for our use case
const AnchorComponent = forwardRef((props, ref) => (
  <a
    {...props}
    ref={ref}
    alt={`${props.value} link`}
    aria-busy={null}
    aria-disabled={null}
    aria-label={null}
    aria-selected={null}
    role={ARIA_ROLES.LINK}
    tabIndex={null}
    width={null}
  >
    <span className='MuiTab-wrapper'>{props?.value}</span>
  </a>
));

export default function DispensarySubheader({
  shouldStick,
  selectedTab,
  onSelectTab,
  infoHref,
  specialsHref,
  brandsHref,
  categoriesHref,
  onToggleSearchInput,
  showSearch,
  hasSpecials,
  subheaderRef,
  hasBrands,
}) {
  const tabsRef = useRef(null);
  const colors = useColors();
  const { t } = useTranslation();
  const hasMissingTab = !hasSpecials || !hasBrands;
  const featureFlags = useFeatureFlags();
  const shouldDisableSearch = featureFlags?.flags['ecomm.configuration.disable-product-search'];
  const { dispensary } = useDispensary();

  const hideDiscount = useHideDiscountFromConsumer(dispensary);

  if (shouldDisableSearch) {
    showSearch = false;
  }

  // TODO if we ever want to clean this up here's a ticket: https://dutchie.atlassian.net/browse/ENG-55143
  useLayoutEffect(() => {
    // so we probably never should have used the tabs component to build our navigation because it semantically
    // makes no sense, but here we are. since MUI thinks it's a tab component, it's going to add aria-selected
    // and other roles that in our use case are plain wrong. rip them out we must.
    if (tabsRef?.current) {
      const { children } = tabsRef.current;
      const tabs = children?.[0]?.children?.[0];
      if (tabs) {
        tabs.setAttribute(`role`, ARIA_ROLES.NAV);
      }
    }
  }, [tabsRef]);

  return (
    <StickySubheader aria-label='top level dispensary navigation' fixed={shouldStick || showSearch} ref={subheaderRef}>
      {!showSearch ? (
        <StyledTabs
          ref={tabsRef}
          $hasMissingTab={hasMissingTab}
          aria-label='links'
          onChange={onSelectTab}
          value={selectedTab}
        >
          <TabLink href={categoriesHref} value={CATEGORIES}>
            <StyledTab
              id='dispensary-nav-header-categories-tab'
              aria-label='categories'
              component={forwardRef((props, ref) => (
                <AnchorComponent ref={ref} {...props} value={CATEGORIES} />
              ))}
              label={t('subheader.categories', CATEGORIES)}
              role={ARIA_ROLES.LINK}
              value={CATEGORIES}
              width={70}
            />
          </TabLink>
          {hasBrands && (
            <TabLink href={brandsHref} value={BRANDS}>
              <StyledTab
                id='dispensary-nav-header-brands-tab'
                aria-label='brands'
                component={forwardRef((props, ref) => (
                  <AnchorComponent ref={ref} {...props} value={BRANDS} />
                ))}
                label={t('subheader.brands', BRANDS)}
                role={ARIA_ROLES.LINK}
                value={BRANDS}
                width={45}
              />
            </TabLink>
          )}
          {hasSpecials && !hideDiscount && (
            <TabLink href={specialsHref} value={SPECIALS}>
              <StyledTab
                id='dispensary-nav-header-specials-tab'
                aria-label='specials'
                component={forwardRef((props, ref) => (
                  <AnchorComponent ref={ref} {...props} value={SPECIALS} />
                ))}
                label={t('subheader.specials', SPECIALS)}
                role={ARIA_ROLES.LINK}
                value={SPECIALS}
                width={53}
              />
            </TabLink>
          )}
          <TabLink href={infoHref} value={INFO}>
            <StyledTab
              id='dispensary-nav-header-info-tab'
              aria-label='dispensary info'
              component={forwardRef((props, ref) => (
                <AnchorComponent ref={ref} {...props} value={INFO} />
              ))}
              label={t('subheader.info', INFO)}
              role={ARIA_ROLES.LINK}
              value={INFO}
              width={25}
            />
          </TabLink>
          {!shouldDisableSearch && (
            <FakeTab
              id='dispensary-nav-header-search-tab'
              aria-label='search'
              component={forwardRef((props, ref) => (
                <AnchorComponent ref={ref} {...props} value={SEARCH} />
              ))}
              onClick={onToggleSearchInput}
              onKeyPress={onToggleSearchInput}
              role={ARIA_ROLES.BUTTON}
              width={20}
            >
              <SearchIcon width={18} height={18} fill={colors.grey[45]} />
            </FakeTab>
          )}
        </StyledTabs>
      ) : (
        <SearchInputContainer role='search'>
          <Search alwaysDisplay onClear={onToggleSearchInput} />
          <CancelButton
            aria-label='Cancel Search'
            onClick={onToggleSearchInput}
            onKeyPress={onToggleSearchInput}
            role={ARIA_ROLES.BUTTON}
          >
            {t('subheader.cancel', 'Cancel')}
          </CancelButton>
        </SearchInputContainer>
      )}
    </StickySubheader>
  );
}

const StickySubheader = styled.div`
  top: inherit;
  z-index: 200;
  position: relative;
  width: 100%;
  height: 55px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.grey[85]}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey[85]}`};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.0408458);
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;

  ${({ fixed }) =>
    fixed &&
    css`
      top: 59px;
      position: fixed;
      border-top: none;
    `};
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  padding-left: 26px;
  padding-right: 26px;
  border-bottom: none !important;
  box-shadow: none;
  height: 100%;

  .MuiTabs-flexContainer {
    justify-content: space-between;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      justify-content: ${({ $hasMissingTab }) => ($hasMissingTab ? `space-evenly` : `space-between`)};
    }
  }
`;

const StyledTab = styled(Tab)`
  ${width}
  &.MuiTab-root {
    text-transform: capitalize;
    margin: 0;
    color: ${({ theme }) => theme.colors.grey[45]};
    padding-top: 16px;

    .MuiTab-wrapper {
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

const CancelButton = styled.div`
  height: 100%;
  margin-left: 11px;
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 21px;
  width: 100%;
  margin-bottom: ${({ fixed }) => (fixed ? `10px` : 0)};
`;

const FakeTab = styled.div`
  ${width}
  color: ${({ theme }) => theme.colors.grey[45]};
  padding-top: 16px;

  svg {
    height: 18px;
    width: 18px;
  }
`;
