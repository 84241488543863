import React, { useEffect, useRef } from 'react';

import useUI from 'hooks/use-ui';
import useScroll from 'hooks/use-scroll';

import DispensaryHeaderDesktop from './dispensary-header-desktop';

const DispensaryHeaderDesktopWrapper = (props) => {
  const UI = useUI();
  const headerRef = useRef();
  const inView = useRef(true);
  const scroll = useScroll();

  useEffect(() => {
    // 1px less than global header height makes transition to sticky header seamless
    const globalHeaderOffset = UI.globalHeaderHeight - 1;
    inView.current = headerRef?.current?.getBoundingClientRect()?.height >= scroll.y - globalHeaderOffset;
    UI.setDispensaryHeaderInView(inView?.current);
  }, [headerRef, scroll, UI]);

  return (
    <div id='dispensary-header' ref={headerRef}>
      <DispensaryHeaderDesktop {...props} />
    </div>
  );
};

export default DispensaryHeaderDesktopWrapper;
