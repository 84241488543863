import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'rebass';
import { useRouter } from 'next/router';

import useTranslation from 'hooks/use-translation';
import useProductQueryParams from 'src/hooks/use-product-query-params';
import { useScrollRestoration } from 'hooks/use-scroll-restoration';
import useUI from 'hooks/use-ui';
import useViewport from 'hooks/use-viewport';

import { CartButton } from 'components/cart';
import { Subheader } from 'components/core';
import { heading5 } from 'components/core/typography';
import NavLinks from 'src/dispensary/core-menu/components/core-menu-header/nav-links';
import MenuTypeDropdown from 'components/menu-type-dropdown';
import Search from 'src/dispensary/components/dispensary-header/search';

export default function DispensarySubheaderDesktop({
  dispensary,
  itemCount,
  isGlobalHeaderInView,
  isDispensaryHeaderInView,
  recDispensary,
  showMenuTypeDropdown,
  onToggleCart,
  cartButtonRef,
}) {
  const router = useRouter();
  const UI = useUI();
  const viewport = useViewport();
  const { queryParams } = useProductQueryParams();
  const { t } = useTranslation();

  // used to short circuit the scroll position restorer when changing search terms
  const searchQueryHasChanged =
    UI.previousSearchTerm && queryParams.search && queryParams.search !== UI.previousSearchTerm;
  // we restore scroll position on back navigation
  // also used to scroll down to sticky header on 2.0 when dispo header is no longer in viewport on route change
  useScrollRestoration({ router, viewport, skip: searchQueryHasChanged });

  return (
    <ContentStretcher>
      <StickySubheader fixed={!isDispensaryHeaderInView}>
        <Container removePadding={!isDispensaryHeaderInView}>
          {!isDispensaryHeaderInView && (
            <>
              <DispensaryImage src={dispensary.logoImage} alt={dispensary.name} />
              <DispensaryName title={dispensary.name}>{dispensary.name}</DispensaryName>
              <Divider />
            </>
          )}
          <NavLinks dispensary={dispensary} />
          <Search />

          <Flex ml='auto'>
            <>
              {!isDispensaryHeaderInView && (
                <MenuTypeContainer>
                  <span>{t('common.menu', 'Menu')}</span>
                  {showMenuTypeDropdown && <MenuTypeDropdown />}
                  {!showMenuTypeDropdown && (
                    <span>
                      {recDispensary ? t('common.recreational', 'Recreational') : t('common.medical', 'Medical')}
                    </span>
                  )}
                </MenuTypeContainer>
              )}
              {!isGlobalHeaderInView && (
                <>
                  <CartButton
                    ref={cartButtonRef}
                    onClick={onToggleCart}
                    variant={itemCount === 0 ? 'grey' : 'orange'}
                    itemCount={itemCount}
                  />
                </>
              )}
            </>
          </Flex>
        </Container>
      </StickySubheader>
    </ContentStretcher>
  );
}

const ContentStretcher = styled.div`
  height: 63px;
`;

const StickySubheader = styled(Subheader)`
  top: 0;
  z-index: 11;
  position: ${({ fixed }) => (fixed ? `fixed` : `relative`)};
  width: 100%;
  .content-wrapper {
    max-width: 1536px;
    padding-left: 63px;
    padding-right: 50px;
    margin: 0 auto;
    ${({ fixed }) =>
      fixed &&
      css`
        padding-left: 27px;
        padding-right: 27px;
      `}
  }
`;

const Container = styled(Flex)`
  width: 100%;
  align-items: center;
  padding-left: ${({ removePadding }) => (removePadding ? 0 : 22)}px;
  height: 100%;
  a:first-of-type {
    padding-left: 0;
  }
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.blueGrey[90]};
  height: 35px;
  width: 1px;
  margin-right: 35px;
`;

const MenuTypeContainer = styled.div`
  height: 44px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 0 2px;
  margin-right: 30px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey[30]};
  span:first-child {
    color: ${({ theme }) => theme.colors.grey[75]};
    text-transform: uppercase;
  }
`;

const DispensaryName = styled.span`
  ${heading5}
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 33px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  line-height: 120%;
  max-width: 179px;
`;

const DispensaryImage = styled.img`
  height: 33px;
  margin-right: 17px;
`;
