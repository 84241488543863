import React from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import useUI from 'hooks/use-ui';
import useCart from 'hooks/use-cart';
import useSwitchMenuType from 'hooks/use-switch-menu-type';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

import { DesktopOnly, MobileOnly } from 'shared/components';
import { isRecOnly, isMedOnly } from 'shared/core/helpers/dispensaries';
import ROUTES from 'src/routes';

import CoreMenuHeader from 'src/dispensary/core-menu/components/core-menu-header';
import DispensarySubheader from 'src/dispensary/components/dispensary-subheader';

import { DispensaryHeaderDesktop, DispensaryHeaderMobile } from './dispensary-headers';

export default function Header() {
  const { pathname } = useRouter();
  const { dispensary } = useDispensary();
  const cart = useCart();
  const menuType = useObserver(() => cart.menuType);
  const UI = useUI();
  const showEmptySearchWhiteSpace = useObserver(() => UI.showEmptySearchWhiteSpace);
  const switchMenuType = useSwitchMenuType();

  if (!dispensary) {
    return null;
  }

  const { offerAnyPickupService, offerDeliveryService } = dispensary.orderTypesConfigV2;

  const disableMenuTypeSelect = isRecOnly(dispensary) || isMedOnly(dispensary);
  const showOrderTypeToggle = offerAnyPickupService && offerDeliveryService;
  const Subheader = UI.isDutchieMain ? DispensarySubheader : CoreMenuHeader;
  const isMenuView = pathname === ROUTES.DISPENSARY_CNAME;

  function handleMenuTypeChange(e) {
    switchMenuType(e.target.value);
  }

  return (
    <>
      <DesktopOnly>
        <DispensaryHeaderDesktop
          dispensary={dispensary}
          showOrderTypeToggle={showOrderTypeToggle}
          menuType={menuType}
          handleMenuTypeChange={handleMenuTypeChange}
          disableMenuTypeSelect={disableMenuTypeSelect}
        />
      </DesktopOnly>

      {isMenuView && !showEmptySearchWhiteSpace && (
        <MobileOnly>
          <DispensaryHeaderMobile
            dispensary={dispensary}
            showOrderTypeToggle={showOrderTypeToggle}
            menuType={menuType}
            handleMenuTypeChange={handleMenuTypeChange}
            disableMenuTypeSelect={disableMenuTypeSelect}
          />
        </MobileOnly>
      )}

      <Subheader dispensary={dispensary} />
      {showEmptySearchWhiteSpace && <EmptySearchWhiteSpace />}
    </>
  );
}

const EmptySearchWhiteSpace = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 113px;
  left: 0px;

  /* 1 less than the header */
  z-index: 2;
`;
