import React from 'react';
import styled from 'styled-components';

import { ContentWrapper } from 'components/core';
import Imgix from 'shared/components/imgix';

import { OrderTypeToggle } from '../../order-type-toggle';
import DispensaryHeaderDetails from '../../dispensary-header-details';
import DispensaryHeaderMenuTypeSelect from '../../dispensary-header-menu-type-select';

export default function DispensaryHeaderMobile({
  dispensary,
  showOrderTypeToggle,
  menuType,
  handleMenuTypeChange,
  disableMenuTypeSelect,
}) {
  const { name, listImage } = dispensary;

  return (
    <StyledContentWrapper data-cy='dispensary-header'>
      <SlantedImageContainer>
        <StyledImgix src={listImage} htmlAttributes={{ alt: `${name} Logo` }} />
      </SlantedImageContainer>

      <DispensaryDetails>
        <DispensaryHeaderDetails dispensary={dispensary} />

        <ActionsContainer>
          {showOrderTypeToggle && <OrderTypeToggle checkDeliveryBoundary />}

          <DispensaryHeaderMenuTypeSelect
            menuType={menuType}
            handleMenuTypeChange={handleMenuTypeChange}
            isDisabled={disableMenuTypeSelect}
          />
        </ActionsContainer>
      </DispensaryDetails>
    </StyledContentWrapper>
  );
}

const ActionsContainer = styled.div`
  display: flex;
`;

const StyledContentWrapper = styled(ContentWrapper)`
  background-color: ${({ theme }) => theme.colors.primaryGrey};
`;

const StyledImgix = styled(Imgix)`
  height: auto;
  width: 100%;
  object-fit: cover;
`;

const BaseImageContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
`;

const SlantedImageContainer = styled(BaseImageContainer)`
  min-height: 250px;
  max-height: 340px;
  clip-path: polygon(0 0, 100% 0, 100% 98%, 0 62%);

  img {
    min-height: 250px;
  }

  :after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 32.67%, rgba(255, 255, 255, 0.76) 100%);
    width: 100%;
    height: 100%;
  }
`;

const DispensaryDetails = styled.div`
  min-height: 156px;
  padding: 0 30px 25px 30px;
  display: flex;
  flex-direction: column;
`;
