import React from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';

import { Link } from 'components/core';
import {
  dispensaryStatusForUser,
  getDeliveryFee,
  openInfoForPickup,
  getDeliveryMinimum,
  getPickupMinimum,
  getOpenNowStatus,
} from 'shared/helpers/dispensaries';
import { openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import { defaultDurationEstimates } from 'shared/constants/orders';
import { formatCurrency } from 'shared/helpers/utils';
import { ORDER_TYPE_PICKUP } from 'shared/constants';

import DeliveryIcon from 'assets/delivery-icon';

const buildPickupMetaData = ({ durationEstimates, t, pickupMinimum, openInfo }) => {
  const pickupMeta = {
    top: [],
    bottom: [],
  };
  const { lowInMinutes, highInMinutes } = durationEstimates.pickup;
  pickupMeta.top.push(
    <EstimateText key={1} data-testid='pickupEstimate'>
      {t('common.readyIn', '{{lowInMinutes}} - {{highInMinutes}} mins', { lowInMinutes, highInMinutes })}
    </EstimateText>
  );

  pickupMeta.bottom.push(
    <BoldWrapper key={2} data-testid='openClose'>
      {getOpenNowStatus(openInfo) ? t('open-now', 'Open Now') : t('closed', 'Closed')}
    </BoldWrapper>
  );

  if (pickupMinimum) {
    pickupMeta.bottom.push(
      <span key={3} data-testid='minimumFee'>
        {t('common.minimumFee', '{{pickupMinimum}} Minimum', { pickupMinimum })}
      </span>
    );
  }

  if (!pickupMinimum) {
    pickupMeta.bottom.push(
      <span key={3} data-testid='noMinimum'>
        {t('common.noMinimum', 'No Minimum')}
      </span>
    );
  }

  return pickupMeta;
};

const buildDeliverymetaData = ({
  dispensary,
  durationEstimates,
  t,
  deliveryMinimum,
  deliveryFee,
  offeringString,
  hasAddress,
}) => {
  const { offerDeliveryService } = dispensary.orderTypesConfigV2;

  const showEstimate = offerDeliveryService && !dispensary.featureFlags?.hideDeliveryEstimate;
  const deliveryMeta = {
    top: [],
    bottom: [],
  };

  if (hasAddress) {
    if (deliveryFee) {
      deliveryMeta.top.push(
        <DeliveryFee key={1} data-testid='deliveryFee'>
          {/* eslint-disable-next-line no-template-curly-in-string */}
          {t('dispensary-header.delivery-fee', '{{deliveryFee}} Delivery Fee', { deliveryFee })}
        </DeliveryFee>
      );
    } else {
      deliveryMeta.top.push(
        <BoldWrapper key={2} data-testid='freeDelivery'>
          <Icon height='17px' />
          <FreeDelivery>{t('dispensary-header.free-delivery', 'Free Delivery')}</FreeDelivery>
        </BoldWrapper>
      );
    }

    if (showEstimate) {
      deliveryMeta.top.push(
        <EstimateText key={3} data-testid='deliveryEstimate'>
          {t('common.readyIn', '{{lowInMinutes}} - {{highInMinutes}} mins', {
            lowInMinutes: durationEstimates?.delivery?.lowInMinutes || defaultDurationEstimates.delivery.lowInMinutes,
            highInMinutes:
              durationEstimates?.delivery?.highInMinutes || defaultDurationEstimates.delivery.highInMinutes,
          })}
        </EstimateText>
      );
    }

    if (_.isNumber(deliveryMinimum)) {
      deliveryMeta.bottom.push(
        <span key={5} data-testid='minimumOrder'>
          {t('common.minimumFee', '{{deliveryMinimum}} Minimum', {
            deliveryMinimum: formatCurrency(deliveryMinimum, { trimZeroCents: true }),
          })}
        </span>
      );
    } else if (_.includes(deliveryMinimum, 'varies')) {
      deliveryMeta.bottom.push(
        <span key={5} data-testid='minimumOrder'>
          {t('common.minimumVaries', 'Delivery fee varies by location')}
        </span>
      );
    } else {
      deliveryMeta.bottom.push(
        <span key={5} data-testid='noDeliveryMinimum'>
          {t('common.noMinimumFee', 'No Minimum')}
        </span>
      );
    }
  }
  deliveryMeta.bottom.push(
    <BoldWrapper key={4} data-testid='deliveryStatus'>
      {_.includes(offeringString, `available`) && !_.includes(offeringString, `Delivery closed`)
        ? t('open-now', 'Open Now')
        : t('closed', 'Closed')}
    </BoldWrapper>
  );
  deliveryMeta.bottom = _.sortBy(deliveryMeta.bottom, [`key`]);
  return deliveryMeta;
};

const MetaInfo = ({
  dispensary,
  pathToInfoPage,
  orderType,
  durationEstimates,
  hasDeliveryAddress,
  t,
  currentRoute,
  hasAddress,
}) => {
  const { href, route } = pathToInfoPage;
  const isInfoPage = _.includes(currentRoute, 'info');

  const { offerAnyPickupService } = dispensary.orderTypesConfigV2;

  const openInfo = openInfoForDispensary(dispensary, {});

  const { type: pickupType } = openInfoForPickup(openInfo);

  const offeringString = dispensaryStatusForUser(dispensary, openInfo);

  const pickupMinimum = getPickupMinimum(dispensary, pickupType);
  let pickupMinimumInDollars = pickupMinimum?.enabled ? pickupMinimum?.minimumInCents / 100 : 0;

  const isDefaultPickup = orderType === null && offerAnyPickupService;

  const deliveryMinimum = getDeliveryMinimum(dispensary, hasDeliveryAddress());

  let info;
  // pickup info
  if (orderType === ORDER_TYPE_PICKUP || isDefaultPickup) {
    if (pickupMinimumInDollars) {
      pickupMinimumInDollars = formatCurrency(pickupMinimumInDollars, { trimZeroCents: true });
    }
    info = buildPickupMetaData({
      durationEstimates,
      t,
      pickupMinimum: pickupMinimumInDollars,
      openInfo,
    });
  } else {
    // delivery info
    const deliveryFee = getDeliveryFee(dispensary, true);

    info = buildDeliverymetaData({
      dispensary,
      durationEstimates,
      t,
      deliveryMinimum,
      deliveryFee,
      offeringString,
      hasAddress,
    });
  }

  // common meta data
  if (!isInfoPage) {
    info.bottom.push(
      <Link key={6} href={href} route={route}>
        <BlueText data-testid='infoLink'>{t('common.moreInfo', 'More Info')}</BlueText>
      </Link>
    );
  }

  return info;
};

export default MetaInfo;

const boldGreyStyles = css`
  color: ${({ theme }) => theme.colors.grey[55]};
  font-size: 12px;
  text-transform: uppercase;
  vertical-align: center;
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const BoldWrapper = styled.div`
  font-weight: bold;
`;

const BlueText = styled.div`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  cursor: pointer;
`;

const EstimateText = styled.span`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${boldGreyStyles}
  }
`;

const DeliveryFee = styled.span`
  font-size: 14px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${boldGreyStyles}
  }
`;

export const FreeDelivery = styled.span`
  color: ${({ theme }) => theme.colors.primaryOrange};
  text-transform: uppercase;
  font-size: 13px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 12px;
  }
`;

export const Icon = styled(DeliveryIcon)`
  margin: 0 7px -6px 0;
  height: auto;
  width: 30px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 22px;
    margin-bottom: -2px;
  }
`;
