import React from 'react';

import { DesktopOnly, MobileOnly } from 'shared/components';
import DispensarySubheaderDesktop from './dispensary-subheader-desktop';
import DispensarySubheaderMobile from './dispensary-subheader-mobile';

export default function DispensarySubheaderWrapper({ dispensary }) {
  return (
    <>
      <DesktopOnly>
        <DispensarySubheaderDesktop dispensary={dispensary} />
      </DesktopOnly>

      <MobileOnly>
        <DispensarySubheaderMobile />
      </MobileOnly>
    </>
  );
}
