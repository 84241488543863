import React, { useCallback, useEffect, useRef } from 'react';

import useUI from 'hooks/use-ui';

import DispensaryHeaderMobile from './dispensary-header-mobile';

const DispensaryHeaderMobileWrapper = (props) => {
  const UI = useUI();
  const headerRef = useRef();
  const inView = useRef(true);

  // Make sure to reset the dispensaryHeaderInView when navigating back to the menu homepage
  useEffect(() => UI.setDispensaryHeaderInView(inView), [UI, inView]);

  const handleScroll = useCallback(() => {
    const scrollTop = document.documentElement?.scrollTop || document.body?.scrollTop;
    const headerHeight = headerRef?.current?.getBoundingClientRect()?.height + 5;
    inView.current = headerHeight >= scrollTop;
    UI.setDispensaryHeaderInView(inView?.current);
  }, [UI]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <div ref={headerRef}>
      <DispensaryHeaderMobile {...props} />
    </div>
  );
};

export default DispensaryHeaderMobileWrapper;
