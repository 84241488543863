import React from 'react';
import { observer } from 'mobx-react';

import useBackwardsCompatibility from 'hooks/use-backwards-compatibility';
import useUI from 'hooks/use-ui';
import useOrderType from 'hooks/use-order-type';
import { useValidateDeliveryAddress } from 'hooks/use-validate-delivery-address';
import { ORDER_TYPE_DELIVERY } from 'shared/constants';

import { OrderTypeToggle } from './order-type-toggle';

export const OrderTypeToggleController = observer(
  (): JSX.Element => {
    useBackwardsCompatibility();

    const UI = useUI();
    const { getOrderType, setOrderType } = useOrderType();
    const orderType = getOrderType();
    const validateDeliveryAddress = useValidateDeliveryAddress();

    const onOrderTypeToggle = async (type): Promise<void> => {
      if (type === orderType) {
        UI.setLoadingOrderType(false);
        return;
      }

      UI.setLoadingOrderType(true);
      if (type === ORDER_TYPE_DELIVERY) {
        const isDeliveryAddressValidated = await validateDeliveryAddress();

        if (isDeliveryAddressValidated) {
          setOrderType(type);
        }
      } else {
        setOrderType(type);
      }

      UI.setLoadingOrderType(false);
    };
    return (
      <OrderTypeToggle loading={UI.loadingOrderType} orderType={orderType} onOrderTypeToggle={onOrderTypeToggle} />
    );
  }
);
