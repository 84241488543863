import React from 'react';
import styled, { css } from 'styled-components';
import Switch from '@material-ui/core/Switch';

import { ClipLoader } from 'react-spinners';
import { ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY, ARIA_ROLES } from 'shared/constants';

import useTranslation from 'hooks/use-translation';
import useColors from 'hooks/use-colors';
import { Clickable } from 'shared/components';

type OrderTypes = 'delivery' | 'pickup';

export type OrderTypeToggleProps = {
  orderType: OrderTypes;
  onOrderTypeToggle: (orderType: OrderTypes) => void;
  loading: boolean;
};

export const OrderTypeToggle = ({
  orderType,
  onOrderTypeToggle,
  loading = false,
}: OrderTypeToggleProps): JSX.Element => {
  const { t } = useTranslation();
  const colors = useColors();

  const loadingColor = colors.white;
  const loadingSize = 20;

  return (
    <ToggleContainer data-testid='orderTypeToggle'>
      <TextLayer $loading={loading}>
        <TextLabel
          $loading={loading}
          aria-checked={orderType === ORDER_TYPE_PICKUP}
          aria-labelledby='pickupToggleLabel'
          data-testid='pickupToggle'
          onClick={() => onOrderTypeToggle(ORDER_TYPE_PICKUP)}
          role={ARIA_ROLES.CHECKBOX}
          selected={orderType === ORDER_TYPE_PICKUP}
        >
          {loading && orderType === ORDER_TYPE_PICKUP ? (
            <ClipLoader color={loadingColor} size={loadingSize} />
          ) : (
            <span id='pickupToggleLabel'>{t('common.pickup', ORDER_TYPE_PICKUP)}</span>
          )}
        </TextLabel>
        <TextLabel
          $loading={loading}
          aria-checked={orderType === ORDER_TYPE_DELIVERY}
          aria-labelledby='deliveryToggleLabel'
          data-testid='deliveryToggle'
          onClick={() => onOrderTypeToggle(ORDER_TYPE_DELIVERY)}
          role={ARIA_ROLES.CHECKBOX}
          selected={orderType === ORDER_TYPE_DELIVERY}
        >
          {loading && orderType === ORDER_TYPE_DELIVERY ? (
            <ClipLoader color={loadingColor} size={loadingSize} />
          ) : (
            <span id='deliveryToggleLabel'>{t('common.delivery', ORDER_TYPE_DELIVERY)}</span>
          )}
        </TextLabel>
      </TextLayer>
      <StyledSwitch
        inputProps={{
          'aria-label': 'order type switch',
          'aria-labelledby': 'orderTypeToggle',
        }}
        checked={orderType === ORDER_TYPE_DELIVERY}
      />
    </ToggleContainer>
  );
};

const StyledSwitch = styled(Switch)`
  &.MuiSwitch-root {
    height: 34px;
    width: 173px;
    padding: 0;
    border-radius: 49px;
    background-color: ${({ theme }) => theme.colors.orderTypeToggleGrey};
    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 162px;
    }
    .MuiSwitch-switchBase {
      transition: none;
    }

    .Mui-checked {
      transform: translateX(83px);
      color: ${({ theme }) => theme.colors.orderTypeToggleGrey};

      + .MuiSwitch-track {
        background-color: ${({ theme }) => theme.colors.grey[90]};
      }

      .MuiSwitch-thumb {
        width: 90px;
        ${({ theme }) => theme.breakpoints.down('sm')} {
          width: 82px;
        }
      }
    }

    .MuiSwitch-track {
      background-color: ${({ theme }) => theme.colors.grey[90]};
      opacity: 1 !important;
    }

    .MuiButtonBase-root {
      padding: 0;
      border-radius: 49px;
    }

    .MuiSwitch-thumb {
      width: 83px;
      height: 34px;
      border-radius: 49px;
      background: ${({ theme }) => theme.colors.grey[35]};
      box-shadow: none;
    }
  }
`;

const TextLabel = styled(Clickable)`
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  width: 100%;
  padding: 9px;
  color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.grey[45])};

  ${({ $loading }) =>
    $loading &&
    css`
      display: flex;
      place-content: center;
      place-items: center;
    `};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 13px;
  }
`;

const TextLayer = styled.div`
  cursor: pointer;
  position: absolute;
  height: 34px;
  width: 173px;
  padding: 0;
  border-radius: 49px;
  z-index: 1;
  color: ${({ theme }) => theme.colors.primaryBlack};
  align-items: center;
  display: flex;
  background: none;
  justify-content: space-between;

  ${TextLabel} {
    &:first-of-type {
      padding-left: ${({ $loading }) => ($loading ? 15 : 19)}px;
      padding-right: 20px;
    }

    &:last-of-type {
      padding-left: 15px;
      padding-right: 18px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 162px;

    ${TextLabel} {
      &:first-of-type {
        padding-left: 20px;
        padding-right: 20px;
      }

      &:last-of-type {
        padding-left: 10px;
        padding-right: 15px;
      }
    }
  }
`;

const ToggleContainer = styled.div`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-right: 9px;
  }
`;
