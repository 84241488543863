import React from 'react';
import styled from 'styled-components';

import { heading2, largeParagraph, paragraph } from 'components/core/typography';

import DispensaryMetaData from './dispensary-header-meta-info-builder';

const DispensaryHeaderDetails = ({ dispensary }) => {
  const { logoImage, name } = dispensary;

  return (
    <DetailsContainer>
      <LogoContainer>
        <Logo alt={`logo for ${name}`} src={logoImage} />
      </LogoContainer>

      <Details>
        <Heading>
          <Name>{name}</Name>
        </Heading>

        <MetaInfo>
          <PickupDeliveryInfo>
            <DispensaryMetaData dispensary={dispensary} />
          </PickupDeliveryInfo>
        </MetaInfo>
      </Details>
    </DetailsContainer>
  );
};

export default DispensaryHeaderDetails;

export const PickupDeliveryInfo = styled.div`
  ${largeParagraph}
  align-items: center;
  color: ${({ theme }) => theme.colors.grey[30]};
  display: flex;
  div,
  span {
    white-space: nowrap;
  }

  > div {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 5px;
    flex-wrap: wrap;
    margin-bottom: 16px;
    width: 100%;
    font-size: 13px;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Heading = styled.div`
  align-items: center;
  display: flex;
`;

export const Logo = styled.img`
  height: inherit;
  width: inherit;
  object-fit: cover;
  border-radius: inherit;
  box-shadow: inherit;
`;

export const LogoContainer = styled.div`
  flex: 0 0 auto;
  min-width: 0;
  margin-right: 34px;
  height: 93px;
  width: 115px;
  border-radius: 7px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.05);

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 9px;

    height: 75px;
    width: 93px;
  }
`;

export const MetaInfo = styled.div`
  ${paragraph}
  color: ${({ theme }) => theme.colors.grey[30]};
  display: flex;
  flex-direction: column;
`;

export const Name = styled.h1`
  ${heading2}
  display: inline-block;
  line-height: 25px;
  padding-right: 23px;
  margin-bottom: 11px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 20px;
    margin-bottom: 0;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex: 0 0 63.5%;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    position: relative;
    z-index: 1;
    max-height: 394px;
    margin-top: 150px;
  }
`;
